<template>
  <div class="new-behavior-container">
    <div class="behavior-card">
      <div class="title">A.机体能力评估问卷</div>
      <div class="behavior-content">
          <Row class="behavior-content-row">
              <Col span="16">1.您近一个月静息血压是否超过140/90mmHg？</Col>
              <Col>
                <RadioGroup v-model="bodyResult[0]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="16">2.您近三个月是否一直在服用降血压或缓解心脏疾患的药物？</Col>
              <Col>
                <RadioGroup v-model="bodyResult[1]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="16">3.您的静息心率是否>75次/分？</Col>
              <Col>
                <RadioGroup v-model="bodyResult[2]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="16">4.您是否有一过性眼⿊、头昏或明确诊断有脑中风？（下蹲起⽴）</Col>
              <Col>
                <RadioGroup v-model="bodyResult[3]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="16">5.您是否有过胸痛、左肩部疼痛或明确诊断有心肌缺⾎、冠心病？</Col>
              <Col>
                <RadioGroup v-model="bodyResult[4]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="16">6.您常速步行1-2公⾥，上3楼是否疲劳、胸痛、心悸、心绞痛？</Col>
              <Col>
                <RadioGroup v-model="bodyResult[5]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="16">7.您常速步行1-2公⾥，上3楼是否胸闷、气促、喘气、呼吸困难？</Col>
              <Col>
                <RadioGroup v-model="bodyResult[6]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="16">8.您运动时是否感觉呼吸不顺畅或有异响（肺活量的达标率&lt;70%？）</Col>
              <Col>
                <RadioGroup v-model="bodyResult[7]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="16">9.您是否近⼀年发⽣过骨痛或骨折？</Col>
              <Col>
                <RadioGroup v-model="bodyResult[8]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="16">10.您近三个⽉是否有关节轻度酸痛不适？</Col>
              <Col>
                <RadioGroup v-model="bodyResult[9]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
      </div>
      <div class="behavior-score">
        <span>问卷评分：</span>
        <span>{{bodyScore}}</span>
      </div>
    </div>
    <div class="behavior-card">
      <div class="title">B.观念行为评估问卷</div>
      <div class="behavior-content">
          <Row class="behavior-content-row">
              <Col span="14">1.您是否有主动运动？</Col>
              <Col>
                <RadioGroup v-model="optionResult[0]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="14">2.您是否运动前有热⾝的准备活动？</Col>
              <Col>
                <RadioGroup v-model="optionResult[1]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="14">3.您是否监测过运动中和运动后的心率？</Col>
              <Col>
                <RadioGroup v-model="optionResult[2]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="14">4.您是否在运动后进行全⾝放松活动？</Col>
              <Col>
                <RadioGroup v-model="optionResult[3]" class="behavior-radio-section">
                    <Radio label="1" class="radio">是</Radio>
                    <Radio label="0" class="radio">否</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="14">5.您进行大步⾛、单车等中等强度运动的感受：</Col>
              <Col>
                <RadioGroup v-model="optionResult[4]" class="behavior-radio-section">
                  <Radio label="1" class="radio">吃力</Radio>
                  <Radio label="2" class="radio">适宜</Radio>
                  <Radio label="3" class="radio">轻松</Radio>
                  <Radio label="0" class="radio">无运动</Radio>
                </RadioGroup>
              </Col>
          </Row>
          <Row class="behavior-content-row">
              <Col span="14">6.您进行哑铃等运动器械或⾝体针对性训练的感受：</Col>
              <Col>
                <RadioGroup v-model="optionResult[5]" class="behavior-radio-section">
                  <Radio label="1" class="radio">吃力</Radio>
                  <Radio label="2" class="radio">适宜</Radio>
                  <Radio label="3" class="radio">轻松</Radio>
                  <Radio label="0" class="radio">无运动</Radio>
                </RadioGroup>
              </Col>
          </Row>
      </div>
      <div class="behavior-score">
        <span>问卷评分：</span>
        <span>{{optionScore}}</span>
      </div>
    </div>
    <div class="symbol-box tac">
      <Button type="primary" size="large" @click="handleBack">返回</Button>
      <Button type="success" size="large" @click="handleSave">保存</Button>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import memberService from '@/services/memberService';

export default {
	data() {
		return {
			bodyResult: [],
			optionResult: [],
		};
	},
	computed: {
		bodyScore() {
			if (this.bodyResult.length === 0) {
				return 0;
			} else {
				return this.bodyResult.reduce((before, last) => Number(before) + Number(last));
			}
		},
		optionScore() {
			if (this.optionResult.length === 0) {
				return 0;
			} else {
				return this.optionResult.reduce((before, last) => Number(before) + Number(last));
			}
		},
	},
	created() {
		memberService
			.newBehaviorData({
				method: 0,
				member_id: this.$route.params.member_id,
			})
			.then((data) => {
				this.bodyResult = data.jtnl.split(',');
				this.optionResult = data.glxw.split(',');
			});
	},
	methods: {
		handleBack() {
			this.$router.back();
		},
		handleSave() {
			if (this.bodyResult.length !== 10 || this.optionResult.length !== 6) {
				this.$Message.warning('请完成问卷调查');
				return;
			}
			const params = {
				method: 1,
				member_id: this.$route.params.member_id,
				jtnl: this.bodyResult.join(','),
				glxw: this.optionResult.join(','),
			};
			memberService.newBehaviorData(params).then(() => {
				this.$Message.success('保存成功');
				this.handleBack();
			});
		},
	},
};
</script>
<style lang="less" scoped>
.new-behavior-container {
  background-color: #fff;
  padding: 20px;
  .behavior-card {
    margin: 0 auto 50px;
    width: 80%;
    font-size: 16px;
    .behavior-content-row {
        margin: 10px 0;
    }
    .title {
      color: blue;
      font-weight: 700;
    }
    .behavior-radio-section {
      display: flex;
      justify-content: space-evenly;
      .radio {
          font-size: 16px;
      }
    }
    .behavior-score {
      font-size: 18px;
      font-weight: 700;
      color: blue;
      margin: 20px 0;
      span:nth-child(2) {
        padding: 0 15px;
        font-size: 32px;
        border-bottom: solid 2px blue;
      }
    }
    &:nth-child(2) {
      .behavior-radio-section {
        display: block;
        .radio {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
